import { Box, Stack, StackProps, Typography } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { SubscriptionPlan } from "../../generated/graphql";
import LockIcon from "@mui/icons-material/Lock";
import useSubscriptionPlan from "../page/dashboard/common/useSubscriptionPlan";
import Link from "next/link";

export const TierColors: any = {
  Free: {
    color: "#66B045",
    backgroundColor: "rgba(127, 176, 105, 0.1)",
  },
  Plus: {
    color: "#FB8932",
    backgroundColor: "#F3E8DF",
  },
  Pro: {
    color: "#E75476",
    backgroundColor: "rgba(251, 137, 50, 0.2)",
  },
  Enterprise: {
    color: "#8060FB",
    backgroundColor: "rgba(128, 96, 251, 0.1);",
  },
};

interface Props extends StackProps {
  minPlan: SubscriptionPlan;
  outline?: number;
  signup?: boolean;
}

export const TierLabel: FC<{ plan: any }> = ({ plan }) => {
  return (
    <Box
      component="span"
      sx={{
        px: 2,
        py: "5px",
        fontWeight: 600,
        borderRadius: "10px",
        ...TierColors[plan || "Free"],
        fontFamily: "Poppins",
      }}
    >
      {plan}
    </Box>
  );
};

const Paywall: FC<PropsWithChildren<Props>> = ({
  children,
  minPlan,
  sx,
  outline = 0,
  signup = false,
  ...rest
}) => {
  const { checkPermision, isLogged } = useSubscriptionPlan();

  const isAvailable = signup || checkPermision(minPlan);

  return (
    <Box position="relative" {...rest}>
      {children}
      {!isAvailable && (
        <Link href={signup ? "/auth/signup" : "/pricing"}>
          <Stack
            sx={{
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              background: "rgb(243 243 243 / 96%)",
              borderRadius: "25px",
              position: "absolute",
              left: -outline / 2 + "px",
              top: -outline / 2 + "px",
              height: `calc(100% + ${outline}px)`,
              width: `calc(100% + ${outline}px)`,
              zIndex: 20,
              ...sx,
            }}
          >
            <LockIcon
              sx={{
                color: TierColors[minPlan].color,
              }}
            />
            <Typography textAlign={"center"}>
              Use this feature with subscription <TierLabel plan={minPlan} /> or
              above
            </Typography>
            {!isLogged && (
              <Link href={"/auth/signup"}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    cursor: "pointer",
                    color: "#0F75FB",
                    textDecoration: "underline",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  Sign up
                </Typography>
              </Link>
            )}
          </Stack>
        </Link>
      )}
    </Box>
  );
};

export default Paywall;

import React, { FC, PropsWithChildren, useState } from "react";
import { SubscriptionPlan } from "../../generated/graphql";
import {
  Box,
  BoxProps,
  Button,
  Stack,
  StackProps,
  SxProps,
  Typography,
} from "@mui/material";
import Switch from "../Button/Switch";
import Icon from "../Icon";
import useSubscriptionPlan from "../../components/page/dashboard/common/useSubscriptionPlan";
import Router from "next/router";
import { useModal } from "../Modal";
import PaywallModal from "../../components/reusable/modals/PaywallModal";
import TitleBoxToogleBar from "./TitleBoxToogleBar";

interface Props {
  id?: string;
  premium?: SubscriptionPlan;
  toggle?: boolean;
  headTitle?: string;
  toogleLabel?: string;
  onToggleChange?: (toggleState: boolean) => void;
  sx?: BoxProps["sx"];
  parentSx?: BoxProps["sx"];
  headButton?: any;
  tooltip?: string;
  tooglePosition?: "right" | "left";
}

const TitleBox: FC<PropsWithChildren<Props>> = ({
  id,
  toggle = false,
  premium = SubscriptionPlan.Free,
  headTitle,
  children,
  onToggleChange,
  toogleLabel,
  sx,
  tooltip,
  headButton,
  parentSx,
  tooglePosition = "right",
}) => {
  const { openModal, renderModal, closeModal } = useModal();
  const { checkPermision } = useSubscriptionPlan();
  const [_toggle, setToggle] = useState(toggle);

  const _onToggleChange = () => {
    if (checkPermision(premium)) {
      onToggleChange && onToggleChange(!_toggle);
      setToggle(!_toggle);
    } else {
      openModal(<PaywallModal onClose={closeModal} minPlan={premium} />);
    }
  };

  return (
    <>
      {renderModal()}
      <Stack
        sx={{
          rowGap: 4,
          ...parentSx,
        }}
      >
        {headTitle && (
          <Stack
            flexDirection={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h4">
              {headTitle}
              {headTitle.length > 2 && (
                <Icon
                  type="DecorationArrowBlue"
                  sx={{
                    display: "inline-block",
                    transform: "translate(10px,7px)",
                  }}
                />
              )}
            </Typography>

            {headButton && headButton}
          </Stack>
        )}
        {toogleLabel ? (
          !_toggle ? (
            <Stack
              sx={{
                p: 3,
                backgroundColor: "#fff",
                borderRadius: "25px",
                ...sx,
              }}
              flexDirection={"row"}
              justifyContent={
                tooglePosition === "right" ? "space-between" : "flex-start"
              }
            >
              <TitleBoxToogleBar
                tooglePosition={tooglePosition}
                tooltip={tooltip}
                onChange={_onToggleChange}
                label={toogleLabel}
                isChecked={_toggle}
              />
            </Stack>
          ) : (
            <Stack
              rowGap={3}
              sx={{
                p: 3,
                backgroundColor: "#fff",
                borderRadius: "25px",
                ...sx,
              }}
            >
              <Stack
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                }}
                flexDirection={"row"}
                justifyContent={
                  tooglePosition === "right" ? "space-between" : "flex-start"
                }
              >
                <TitleBoxToogleBar
                  tooglePosition={tooglePosition}
                  tooltip={tooltip}
                  onChange={_onToggleChange}
                  label={toogleLabel}
                  isChecked={_toggle}
                />
              </Stack>
              {children}
            </Stack>
          )
        ) : (
          <Stack
            rowGap={3}
            sx={{
              p: 3,
              backgroundColor: "#fff",
              borderRadius: "25px",
              ...sx,
            }}
          >
            {children}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default TitleBox;

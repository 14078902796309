import React, { FC, PropsWithChildren } from "react";
import { Stack } from "@mui/system";
import { StackProps } from "@mui/material";

interface Props {
  id?: string;
  type?: any[];
  sx?: StackProps["sx"];
}

const InputGroup: FC<PropsWithChildren<Props>> = ({
  children,
  type = ["row"],
  sx,
}) => {
  return (
    <Stack
      width={"100%"}
      flexDirection={type}
      columnGap={1}
      rowGap={1}
      alignItems="flex-start"
      sx={sx as any}
    >
      {children}
    </Stack>
  );
};

export default InputGroup;

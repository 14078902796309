import React, { FC, PropsWithChildren, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "@emotion/styled";

type Image = {
  preview: any;
  file: Blob;
};

interface Props {
  label?: any;
  children: React.ReactElement;
  onDropImage: (image: Image) => void;
  onRemove?: () => void;
  onError?: (err: string) => void;
  image?: Image;
  preview?: string;
  maxSize?: number;
}

const ChildrenWr = styled.div`
  width: 100%;
`;
const DropInput = styled.input``;

const Dropzone: FC<PropsWithChildren<Props>> = ({
  onDropImage,
  onRemove,
  onError,
  image,
  children,
  preview,
  maxSize = 9000000,
}) => {
  const onDrop = useCallback((acceptedFiles: any, err: any) => {
    if (err.length > 0) {
      if (err[0].errors[0].code === "file-too-large") {
        onError && onError(`File is larger than ${maxSize / 1000000} MB`);
      }
    }

    if (acceptedFiles.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]); // converts the blob to base64 and calls onload

      reader.onload = function (f) {
        f.stopPropagation();
        onDropImage({
          preview: f.target?.result,
          file: acceptedFiles[0],
        });
      };
    }
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/jpeg, image/png",
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: !!children,
    maxSize: maxSize,
  });

  return (
    <ChildrenWr {...getRootProps()}>
      <DropInput {...getInputProps()} />
      {React.cloneElement(children, {
        ...children.props,
        onClick: open,
      })}
    </ChildrenWr>
  );
};

export default Dropzone;

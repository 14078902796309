import { Typography, TypographyProps } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";

interface Props {
  id: string;
  isSelected: boolean;
  onSelectItem: (id: string) => void;
  sx?: TypographyProps["sx"];
}

const DropdownButtonItem: FC<PropsWithChildren<Props>> = ({
  id,
  children,
  onSelectItem,
  isSelected,
  sx,
}) => {
  const onSelect = () => {
    onSelectItem(id);
  };

  return (
    <Typography
      component="li"
      sx={{
        fontSize: "14px",
        color: "#515151",
        listStyleType: "none",
        p: 1,
        "&:hover": {
          backgroundColor: "#F3F4F5",
          color: "#000000",
          borderRadius: 2,
        },
        borderRadius: 2,
        backgroundColor: isSelected ? "#F3F4F5" : "#fff",
        cursor: "pointer",
        ...sx,
      }}
      onClick={onSelect}
    >
      {children}
    </Typography>
  );
};

export default DropdownButtonItem;

import React, { FC } from "react";
import { styled } from "@mui/material";
import MuiSwitch, { SwitchProps } from "@mui/material/Switch";

interface Props extends SwitchProps {
  type?: "green" | "blue";
}

const StyledSwitch = styled(MuiSwitch)<any>(
  ({ $type, theme, ...props }) => `
  width: ${$type === "blue" ? 60 : 40}px;
  height: ${$type === "blue" ? 30 : 20}px;
  padding: 0;
  & .MuiSwitch-switchBase {
    padding: 0;
    margin: ${$type === "blue" ? 3 : 2}px;
    transition-duration: 300ms;

    &.Mui-checked {
      transform: ${$type === "blue" ? "translateX(28px)" : "translateX(20px)"};
      color: #ffffff;
      & + .MuiSwitch-track {
        background-color: ${$type === "blue" ? "#0F75FB" : "#65C466"};
        opacity: 1;
        border: 0;
      }
      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }

      & .MuiSwitch-thumb {
        color:#ffffff;
      }
    }

    &.Mui-focusVisible .MuiSwitch-thumb {
      color: #33cf4d;
      border: 6px solid #fff;
    }

    & .MuiSwitch-thumb {
      color:#ffffff;
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: ${theme.palette.mode === "light" ? 0.7 : 0.3};
      background-color:  #F7F7F7;
    }

    &.Mui-disabled {
      & .MuiSwitch-thumb {
        color:#CDCECF;
      }
    }
  }

  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: ${$type === "blue" ? "24px" : "16px"};
    height: ${$type === "blue" ? "24px" : "16px"};
  }
  & .MuiSwitch-track {
    border-radius:${$type === "blue" ? "66px" : "13px"};
    background-color: ${theme.palette.mode === "light" ? "#515151" : "#39393D"};
    opacity: 1;
    transition: ${theme.transitions.create(["background-color"], {
      duration: 500,
    })};
  }
`
);

const Switch: FC<Props> = ({ type = "blue", ...props }) => (
  <StyledSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    $type={type}
    {...props}
  />
);

export default Switch;

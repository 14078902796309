import {
  ClickAwayListener,
  Popper,
  PopperProps,
  Stack,
  StackProps,
} from "@mui/material";
import React, { FC, PropsWithChildren, ReactElement } from "react";

interface Props extends StackProps {
  button: ReactElement;
  placement?: PopperProps["placement"];
}

const DropdownButton: FC<PropsWithChildren<Props>> = ({
  button,
  children,
  sx,
  placement,
  ...p
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <>
      {React.cloneElement(button, {
        ...button.props,
        onClick: handleClick,
      })}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={placement || "bottom"}
      >
        <ClickAwayListener
          onClickAway={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorEl(null);
          }}
        >
          <Stack
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
              p: 2,
              rowGap: "3px",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
              ...sx,
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f7f7f7",
                borderRadius: "99px",
              },

              "&::-webkit-scrollbar": {
                width: "8px",
              },

              "&::-webkit-scrollbar-thumb": {
                borderRadius: "99px",
                backgroundColor: "#3d8ffa",
                border: "2px #f7f7f7 solid",
              },
            }}
          >
            {React.Children.map(children, (c: any) =>
              React.cloneElement(c as ReactElement, {
                onClick: () => {
                  c.props.onClick();
                  setAnchorEl(null);
                },
              })
            )}
          </Stack>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default DropdownButton;

import { Box, BoxProps } from "@mui/material";
import React, { FC, useEffect, useRef } from "react";
import * as icons from "./icons";

interface Props extends BoxProps {
  type: keyof typeof icons;
}

const Icon = React.forwardRef<any, Props>(({ type, sx, ...props }, ref) => {
  const Icon = icons[type];
  return (
    <Box
      component="span"
      {...props}
      ref={ref}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <Icon
        style={{
          width: props.width,
          height: props.height,
          fill: props.color,
        }}
      />
    </Box>
  );
});

export default Icon;

import React, {
  FC,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  FilledTextFieldProps,
  Popover,
  Popper,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { Calendar, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import ClearIcon from "@mui/icons-material/Clear";
import InputGroup from "./InputGroup";
import { DateInterval } from "../../generated/graphql";
import { getDateInterval } from "../../utils/useDate";

interface Props
  extends Omit<FilledTextFieldProps, "value" | "onChange" | "variant"> {
  interval?: boolean;
  withHours?: boolean;
  deletable?: boolean;
  lastDates?: boolean;
  value?: string;
  intervalValue?: DateInterval;
  onChange: (date: any) => void;
}

const HOURS = [
  {
    v: 1,
    am: 1,
    pm: 13,
  },
  {
    v: 2,
    am: 2,
    pm: 14,
  },
  {
    v: 3,
    am: 3,
    pm: 15,
  },
  {
    v: 4,
    am: 4,
    pm: 16,
  },
  {
    v: 5,
    am: 5,
    pm: 17,
  },
  {
    v: 6,
    am: 6,
    pm: 18,
  },
  {
    v: 7,
    am: 7,
    pm: 19,
  },
  {
    v: 8,
    am: 8,
    pm: 20,
  },
  ,
  {
    v: 9,
    am: 9,
    pm: 21,
  },
  ,
  {
    v: 10,
    am: 10,
    pm: 22,
  },
  {
    v: 11,
    am: 11,
    pm: 23,
  },
  ,
  {
    v: 0,
    am: 0,
    pm: 24,
  },
];

const getAmPmInceremnt = (amPm: string, h: number) => {
  if (amPm === "AM") {
    return h >= 12 ? -12 : 0;
  }
  return h >= 12 ? 0 : 12;
};

const InputDate: FC<Props> = ({
  onChange,
  value,
  interval = false,
  withHours = true,
  deletable = true,
  lastDates = false,
  label,
  intervalValue,
  ...rest
}) => {
  const [date, setDate] = useState<Date | undefined>(
    value ? new Date(value) : undefined
  );
  const [opened, setOpened] = useState(null);
  const [state, setState] = useState([
    {
      startDate: intervalValue?.from
        ? new Date(intervalValue?.from)
        : new Date(),
      endDate: intervalValue?.to ? new Date(intervalValue?.to) : null,
      key: "selection",
    },
  ]);

  const is = date ? ((date as any).getHours() - 12 > 12 ? "AM" : "PM") : "AM";

  const [amPm, setAmPM] = useState(is);

  const onClickField = (event: any) => {
    setOpened(event.currentTarget);
  };

  const onChangeHour = (h: number) => {
    if (date) {
      setDate(new Date(date.setHours(h)));
    }
  };

  useEffect(() => {
    if (date) {
      const hour = date.getHours();
      setDate(new Date(date.setHours(hour)));
    }
  }, [amPm]);

  useEffect(() => {
    if (intervalValue) {
      setState([
        {
          startDate: new Date(intervalValue?.from || "1/1/2020"),
          endDate: new Date(intervalValue?.to || "1/1/2020"),
          key: "selection",
        },
      ]);
    }
  }, [intervalValue]);

  const onClose = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOpened(null);
    onChange(
      interval
        ? {
            from: state[0].startDate.toLocaleDateString("en"),
            to: state[0].endDate?.toLocaleDateString("en"),
          }
        : date
    );
  };

  const renderCalendar = () =>
    interval ? (
      //@ts-ignore
      <DateRange
        editableDateInputs={true}
        //@ts-ignore
        onChange={(item) => setState([item.selection])}
        moveRangeOnFirstSelection={false}
        //@ts-ignore
        ranges={state}
      />
    ) : (
      //@ts-ignore
      <Calendar
        onChange={(item) => {
          const hour = item.getHours();
          const increment = getAmPmInceremnt(amPm, hour);
          setDate(new Date(item.setHours(hour + increment)));
        }}
        date={date}
      />
    );

  const getDateInputFormat = () => {
    if (!interval) {
      const inputValueDate = date ? (date as any).toLocaleDateString("en") : "";
      const inputValueTime = withHours
        ? date
          ? (date as any).toLocaleTimeString("en")
          : ""
        : "";
      if (!inputValueDate && !inputValueTime) return "";
      return inputValueDate + " " + inputValueTime;
    }

    if (state[0].endDate !== null) {
      const from = state[0].startDate.toLocaleDateString("en");
      const to = (state[0].endDate as Date).toLocaleDateString("en");

      return from + " to " + to;
    }

    return "";
  };

  return (
    <>
      <TextField
        id="d"
        label={label}
        onClick={onClickField}
        value={getDateInputFormat()}
        {...rest}
        InputProps={{
          disableUnderline: true,
          endAdornment: deletable && (
            <ClearIcon
              onClick={(e) => {
                e.stopPropagation();
                onChange(null);
                setDate(undefined);
              }}
              sx={{
                cursor: "pointer",
              }}
            />
          ),
        }}
      />

      <Popper
        id={"d"}
        sx={{
          zIndex: 10,
          borderRadius: "13px",
        }}
        open={!!opened}
        anchorEl={opened}
      >
        <ClickAwayListener onClickAway={onClose}>
          <Box
            sx={{
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
              borderRadius: "13px",
              backgroundColor: "white",
              "& .rdrDayNumber, .rdrWeekDay, .rdrMonthAndYearWrapper": {
                fontFamily: "Poppins",
                color: "#000000",
              },
              "& .rdrCalendarWrapper": {
                borderRadius: "13px",
                zIndex: 1,
              },
            }}
          >
            {renderCalendar()}
            {/*interval && (
              <Stack
                flexDirection={"row"}
                sx={{
                  px: 2,
                  width: "100%",
                  maxWidth: "332px",
                  justifyContent: "center",
                }}
                flexWrap="wrap">
                {Intervals.map((h, k) => (
                  <Typography
                    key={k}
                    onClick={() => {
                      const { from, to } = getDateInterval(h.i);
                      setState([
                        {
                          startDate: new Date(from),
                          endDate: new Date(to),
                          key: "selection",
                        },
                      ]);
                    }}
                    sx={{
                      color: "primary.main",
                      textDecoration: "underline",
                      p: 2,
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "none",
                      },
                    }}>
                    {h.v}
                  </Typography>
                ))}
              </Stack>
                  )*/}
            {withHours && (
              <>
                <Typography px={2}>Hours</Typography>
                <Stack
                  flexDirection={"row"}
                  sx={{
                    px: 2,
                    width: "100%",
                    maxWidth: "332px",
                  }}
                  flexWrap="wrap"
                >
                  {HOURS.map((h: any, k) => (
                    <Box
                      onClick={() => onChangeHour(amPm === "AM" ? h.am : h.pm)}
                      key={k}
                      sx={{
                        cursor: "pointer",
                        textAlign: "center",
                        p: 2,
                        fontFamily: "Poppins",
                        width: "calc(100% / 6)",
                        border: "1px solid #F5F5F5",
                        "&:hover": {
                          border: "1px solid #0F75FB",
                        },
                      }}
                    >
                      {h.v}
                    </Box>
                  ))}
                </Stack>
                <InputGroup sx={{ p: 2 }}>
                  <Button
                    fullWidth={true}
                    size="small"
                    variant={amPm === "AM" ? "primary" : "outlined"}
                    onClick={() => setAmPM("AM")}
                  >
                    AM
                  </Button>
                  <Button
                    fullWidth={true}
                    size="small"
                    onClick={() => setAmPM("PM")}
                    variant={amPm === "PM" ? "primary" : "outlined"}
                  >
                    PM
                  </Button>
                </InputGroup>
              </>
            )}
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default InputDate;

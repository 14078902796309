import {
  ButtonProps,
  Button as MaterialButton,
  CircularProgress,
  Box,
} from "@mui/material";
import React, { FC, PropsWithChildren } from "react";

interface Props extends ButtonProps {
  isLoading?: boolean;
}

const Button: FC<PropsWithChildren<Props>> = ({
  children,
  isLoading,
  size = "large",
  disabled,
  ...rest
}) => {
  const loaderSize: any = {
    small: 24,
    large: 40,
  };

  return (
    <MaterialButton {...rest} size={size} disabled={disabled || isLoading}>
      <>
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              width: loaderSize[size] + "px !important",
              height: loaderSize[size] + "px !important",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <CircularProgress
              color="inherit"
              sx={{
                width: loaderSize[size] + "px !important",
                height: loaderSize[size] + "px !important",
              }}
            />
          </Box>
        )}
        <span
          style={{
            visibility: isLoading ? "hidden" : "visible",
          }}
        >
          {children}
        </span>
      </>
    </MaterialButton>
  );
};

Button.displayName = "Button";

export default Button;

import {
  styled,
  Radio as MuiRadio,
  RadioProps,
  FormControlLabel,
} from "@mui/material";
import React, { FC } from "react";

interface Props extends RadioProps {
  id?: string;
  label?: any;
}

const StyledRadio = styled(MuiRadio)``;

const Radio: FC<Props> = ({ label, id, ...radioProps }) => {
  return (
    <FormControlLabel
      control={<StyledRadio id={id} {...radioProps} />}
      label={label}
    />
  );
};

export default React.memo(Radio);

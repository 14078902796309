import {
  styled,
  Slider as MSlider,
  SliderProps,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import React, { FC } from "react";

interface Props extends SliderProps {
  labels?: {
    position: number;
    label: string;
  }[];
}

const MuiSlider = styled(MSlider)`
  padding: 9px 0;

  .MuiSlider-rail {
    height: 15px;
    background-color: #edecec;
    border-radius: 500px;
  }
  .MuiSlider-track {
    display: none;
  }

  .MuiSlider-thumbColorPrimary {
    width: 15px !important;
    height: 15px !important;
    background: #0f75fb;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);

    &:before {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);
    }
  }
`;

const Slider: FC<Props> = ({ labels, ...props }) => {
  return !labels ? (
    <MuiSlider {...props} />
  ) : (
    <Box position="relative">
      <MuiSlider {...props} />
      <Stack flexDirection={"row"} justifyContent="space-between">
        {labels?.map(({ position, label }, _) => (
          <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            {label}
          </Typography>
        ))}
      </Stack>
    </Box>
  );
};

export default Slider;

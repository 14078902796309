import { Typography, Tooltip } from "@mui/material";
import Switch from "../Button/Switch";
import React from "react";
import Icon from "../Icon";

interface Props {
  tooglePosition?: "right" | "left";
  label: string;
  onChange: () => void;
  isChecked: boolean;
  tooltip?: string;
}

const TitleBoxToogleBar = ({
  tooglePosition,
  label,
  onChange,
  tooltip,
  isChecked,
}: Props) => {
  const tooltipComponent = tooltip && (
    <Tooltip title={tooltip} placement="right">
      <Icon type="Info" marginLeft="8px" />
    </Tooltip>
  );

  return tooglePosition === "right" ? (
    <>
      <Typography>{label}</Typography>
      {tooltipComponent}
      <Switch checked={isChecked} onChange={onChange} />
    </>
  ) : (
    <>
      <Switch checked={isChecked} onChange={onChange} />
      <Typography pl="16px">{label}</Typography>
      {tooltipComponent}
    </>
  );
};

export default TitleBoxToogleBar;

import { Box, Button, Stack, StackProps, Typography } from "@mui/material";
import React, { FC } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { TierColors, TierLabel } from "../Paywall";
import { SubscriptionPlan } from "../../../generated/graphql";
import { InputGroup } from "../../../ui";
import Router from "next/router";

interface Props {
  minPlan: SubscriptionPlan;
  onClose: any;
}

const PaywallModal: FC<Props> = ({ onClose, minPlan }) => {
  const redirectToPricing = () => {
    Router.push("/pricing");
  };

  return (
    <Stack
      rowGap={3}
      maxWidth="1000px"
      sx={{
        p: {
          sx: 0,
          sm: 5,
        },
        backgroundColor: "#ffffff",
        borderRadius: "15px",
        alignItems: "center",
        width: {
          xs: "100%",
          sm: "565px",
        },
      }}
    >
      <LockIcon
        sx={{
          color: TierColors[minPlan].color,
        }}
      />
      <Typography>
        Use this feature with subscription <TierLabel plan={minPlan} /> or above
      </Typography>
      <InputGroup
        sx={{
          mt: 3,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
      >
        <Button variant="primary" fullWidth={true} onClick={redirectToPricing}>
          Upgrade Subscription
        </Button>
        <Button fullWidth={true} variant="close" onClick={onClose}>
          Cancel
        </Button>
      </InputGroup>
    </Stack>
  );
};

export default PaywallModal;
